import { render, staticRenderFns } from "./TagInterest.vue?vue&type=template&id=ada24938&scoped=true"
import script from "./tagInterest.ts?vue&type=script&lang=ts&external"
export * from "./tagInterest.ts?vue&type=script&lang=ts&external"
import style0 from "./TagInterest.vue?vue&type=style&index=0&id=ada24938&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ada24938",
  null
  
)

export default component.exports